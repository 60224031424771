import Vue from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import './assets/less/style.less';
import App from './App.vue';
import router from './router';
import store from './store';
import http from './public/http';
import * as url from './public/url';
import * as constant from './public/constant';

Vue.prototype.$url = url;
Vue.prototype.$http = http;
Vue.prototype.$constant = constant;
Vue.config.productionTip = false;
Vue.use(Antd);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
