const session = {};

session.setSessionId = (data) => {
    if (data) {
        localStorage.setItem('KSESSIONID', data);
    }
};

session.getSessionId = () => {
    if (localStorage.getItem('KSESSIONID')) {
        return localStorage.getItem('KSESSIONID');
    }
    return '';
};

export default session;
