import trim from 'lodash.trim';
import * as $url from '../../public/url';
import http from '../../public/http';
import rememberMe from '../../public/remember-me';
import ticket from '../../public/ticket';
import session from '../../public/session';
import * as constant from '../../public/constant';

const router = {
  state: {
    data: {
      errorMsg: '',
      error: false,
      loading: true,
      appInfo: {},
      loginResult: {},
      clientId: '',
      responseType: '',
      timer: 60,
      isTenant: true,
      isFast: false,
      isRememberMe: false,
      captchaUrl: '',
      isCaptcha: false,
    },
    userLogin: {
      tenant: '',
      redirectUrl: '',
      appKey: '',
      userName: '',
      password: '',
      captcha: '',
      identityType: ''
    },
    phoneLogin: {
      tenant: '',
      appKey: '',
      phone: '',
      code: '',
      captcha: ''
    }
  },
  mutations: {
    init(state) {
      const queryStringParam = $url.getQueryString(window.location.href);
      if (!queryStringParam || !queryStringParam.clientId || !queryStringParam.redirectUrl) {
        state.data.error = true;
        state.data.errorMsg = '非法请求';
        state.data.loading = false;
        return;
      }

      state.data.isRememberMe = rememberMe.isRemember();
      state.userLogin.appKey = queryStringParam.clientId;
      state.userLogin.redirectUrl = queryStringParam.redirectUrl;
      state.phoneLogin.appKey = queryStringParam.clientId;
      state.phoneLogin.redirectUrl = queryStringParam.redirectUrl;
      state.data.loading = false;
    },

    copyRememberMeUser(state) {
      const data = rememberMe.getUser(state.userLogin.appKey);
      if (data) {
        state.userLogin.tenant = data.tenant ? data.tenant : '';
        state.userLogin.userName = data.userName ? data.userName : '';
        state.userLogin.password = data.password ? data.password : '';
      }
    },

    copyRememberMePhone(state) {
      const data = rememberMe.getPhone(state.userLogin.appKey);
      if (data) {
        state.phoneLogin.tenant = data.tenant ? data.tenant : '';
        state.phoneLogin.phone = data.phone ? data.phone : '';
      }
    },

    rememberMe(state, payload) {
      state.data.isRememberMe = payload;
      rememberMe.setRemember(state.userLogin.appKey, payload);
    },

    getAppInfo(state, payload) {
      state.data.appInfo = payload;
      state.data.isTenant = state.data.appInfo.tenant;
      if (!state.data.isTenant) {
        state.userLogin.tenant = 'KTM';
        state.phoneLogin.tenant = 'KTM';
      }
    },

    timerDown(state) {
      const clearTimer = setInterval(() => {
        state.data.timer -= 1;
        if (state.data.timer === 0) {
          clearInterval(clearTimer);
        }
      }, 1000);
    },
  },

  actions: {
    init({ commit }) {
      commit('init');
    },

    copyRememberMeUser({ commit }) {
      commit('copyRememberMeUser');
    },

    copyRememberMePhone({ commit }) {
      commit('copyRememberMePhone');
    },

    rememberMe({ commit }, payload) {
      commit('rememberMe', payload);
    },

    getAppInfo({ state, commit }) {
      return new Promise((resolve) => {
        state.data.loading = true;
        // const url = $url.getBackendApiUrl('apply/find/v2');
        const url = $url.getBackendApiUrl('preauth/init');
        const data = { appKey: state.userLogin.appKey, redirectUrl: state.userLogin.redirectUrl, ticketRequests: ticket.getTickets() };
        http.post(url, data, $url.default_headers).then((res) => {
          state.data.loading = false;

          if (res.status === 200 && res.data.success && res.data.data && res.data.data.data) {
            window.location.href = decodeURIComponent(`${res.data.data.data}`);
          }

          if (res.status === 200 && res.data.success && res.data.data && res.data.data.name) {
            commit('getAppInfo', res.data.data);

            if (state.data.appInfo.applyStyle) {
              commit('changeConfig', state.data.appInfo.applyStyle);
              if (state.data.appInfo.applyStyle.customCss) {
                commit('loadCssCode');
              }
            }
            session.setSessionId(res.data.data.sessionId);
            resolve(res.data.data);
          } else if (res.status === 200 && !res.data.success) {
            state.data.error = true;
            state.data.errorMsg = res.data.errMessage;
          } else {
            state.data.error = true;
            state.data.errorMsg = '无法识别的应用';
          }
        }).catch((e) => {
          console.error(e);
          state.data.loading = false;
          state.data.error = true;
          state.data.errorMsg = '无法识别当前登录系统的应用,请联系开发人员配置正确应用';
        });
      });
    },

    loginByUserName({ state }, self) {
      return new Promise((resolve) => {
        if (state.data.error) {
          self.$message.error(state.data.errorMsg);
          return;
        }

        if (!trim(state.userLogin.tenant)) {
          state.data.error = false;
          state.data.errorMsg = '请输入企业域';
          return;
        }

        if (!trim(state.userLogin.userName)) {
          state.data.error = false;
          state.data.errorMsg = '请输入用户名';
          return;
        }

        if (!trim(state.userLogin.password)) {
          state.data.error = false;
          state.data.errorMsg = '请输入密码';
          return;
        }

        rememberMe.setUser(state.userLogin.appKey, state.userLogin);
        state.data.loading = true;
        const url = $url.getBackendApiUrl('authencation/oauth2/login/user');
        http.post(url, state.userLogin, { headers: { 'Content-type': 'application/json;charset=UTF-8', tenant: state.userLogin.tenant } }).then((res) => {
          state.data.loading = false;
          if (res.status !== 200) {
            state.data.error = false;
            state.data.errorMsg = '登录失败';
            resolve(constant.CAPTCHA);
            return;
          }

          if (!res.data.success) {
            state.data.error = false;
            state.data.errorMsg = res.data.errMessage;
            resolve(constant.CAPTCHA);
            return;
          }

          if (res.data.success) {
            ticket.setTicket(res.data.data);
            if (res.data.data.duplicateLoginTip) {
              self.$confirm({
                title: '提示',
                content: '当前用户已经在别的设备登录，您确认要继续登录吗？',
                okText: '确认继续登录',
                cancelText: '取消',
                onOk() {
                  if (res.data.data.expires) {
                    self.$confirm({
                      title: '提示',
                      content: `你的密码已经过期${res.data.data.expiresDays}天,请前往个人中心修改密码`,
                      okText: '知道了',
                      cancelButtonProps: {
                        style: { display: 'none' }
                      },
                      onOk() {
                        if (res.data.data.mfaMessageCode || res.data.data.mfaEmailCode) {
                          state.data.loginResult = res.data.data;
                          resolve(constant.MFA);
                        } else {
                          window.location.href = decodeURIComponent(`${res.data.data.data}`);
                        }
                      }
                    });
                  } else {
                    if (res.data.data.mfaMessageCode || res.data.data.mfaEmailCode) {
                      state.data.loginResult = res.data.data;
                      resolve(constant.MFA);
                    } else {
                      window.location.href = decodeURIComponent(`${res.data.data.data}`);
                    }
                  }
                }
              });
            } else {
              if (res.data.data.expires) {
                self.$confirm({
                  title: '提示',
                  content: `你的密码已经过期${res.data.data.expiresDays}天,请前往个人中心修改密码`,
                  okText: '知道了',
                  cancelButtonProps: {
                    style: { display: 'none' }
                  },
                  onOk() {
                    if (res.data.data.mfaMessageCode || res.data.data.mfaEmailCode) {
                      state.data.loginResult = res.data.data;
                      resolve(constant.MFA);
                    } else {
                      window.location.href = decodeURIComponent(`${res.data.data.data}`);
                    }
                  }
                });
              } else {
                if (res.data.data.mfaMessageCode || res.data.data.mfaEmailCode) {
                  state.data.loginResult = res.data.data;
                  resolve(constant.MFA);
                } else {
                  window.location.href = decodeURIComponent(`${res.data.data.data}`);
                }
              }
            }
          }
        }).catch((e) => {
          console.error(e);
          state.data.error = false;
          state.data.errorMsg = '登录失败';
          state.data.loading = false;
        });
      });
    },

    send({ state, commit }, self) {
      return new Promise((resolve) => {
        if (state.data.error) {
          self.$message.error(state.data.errorMsg);
          return;
        }

        if (!trim(state.phoneLogin.tenant)) {
          state.data.error = false;
          state.data.errorMsg = '请输入企业域';
          return;
        }

        if (!trim(state.phoneLogin.phone)) {
          state.data.error = false;
          state.data.errorMsg = '请输入手机号';
          return;
        }

        rememberMe.setPhone(state.userLogin.appKey, state.phoneLogin);
        state.data.loading = true;
        const url = $url.getBackendApiUrl('authencation/oauth2/login/send/message/code');
        http.post(url, state.phoneLogin, { headers: { 'Content-type': 'application/json;charset=UTF-8', tenant: state.phoneLogin.tenant } }).then((res) => {
          if (res.status === 200 && res.data.success) {
            self.$message.success('短信验证码发送成功');
            state.data.error = false;
            state.data.errorMsg = '';
            state.data.timer = 59;
            commit('timerDown');
          } else if (res.status === 200 && !res.data.success && res.data.errCode === 'PHONE_SEND_MESSAGE_CODE_COUNT_LIMIT') {
            state.data.error = false;
            state.data.errorMsg = '请不要频繁操作获取验证码';
            state.data.timer = 59;
            commit('timerDown');
          } else {
            state.data.error = false;
            state.data.errorMsg = res.data.errMessage;
            resolve();
          }
          state.data.loading = false;
        }).catch((e) => {
          console.error(e);
          state.data.loading = false;
          self.$message.error('短信验证码获取失败');
        });
      });
    },

    loginByPhone({ state }, self) {
      return new Promise((resolve) => {
        if (state.data.error) {
          self.$message.error(state.data.errorMsg);
          return;
        }

        if (!trim(state.phoneLogin.tenant)) {
          state.data.error = false;
          state.data.errorMsg = '请输入企业域';
          return;
        }

        if (!trim(state.phoneLogin.phone)) {
          state.data.error = false;
          state.data.errorMsg = '请输入手机号';
          return;
        }

        if (!trim(state.phoneLogin.code)) {
          state.data.error = false;
          state.data.errorMsg = '请输入验证码';
          return;
        }

        rememberMe.setPhone(state.userLogin.appKey, state.phoneLogin);
        state.data.loading = true;
        const url = $url.getBackendApiUrl('authencation/oauth2/login/phone');
        http.post(url, state.phoneLogin, { headers: { 'Content-type': 'application/json;charset=UTF-8', tenant: state.phoneLogin.tenant } }).then((res) => {
          state.data.loading = false;
          if (res.status !== 200) {
            state.data.error = false;
            state.data.errorMsg = '登录失败';
            resolve(constant.CAPTCHA);
            return;
          }

          if (!res.data.success) {
            state.data.error = false;
            state.data.errorMsg = res.data.errMessage;
            resolve(constant.CAPTCHA);
            return;
          }

          if (res.data.success) {
            ticket.setTicket(res.data.data);
            if (res.data.data.duplicateLoginTip) {
              self.$confirm({
                title: '提示',
                content: '当前用户已经在别的设备登录，您确认要继续登录吗？',
                okText: '确认继续登录',
                cancelText: '取消',
                onOk() {
                  if (res.data.data.expires) {
                    self.$confirm({
                      title: '提示',
                      content: `你的密码已经过期${res.data.data.expiresDays}天,请前往个人中心修改密码`,
                      okText: '知道了',
                      cancelButtonProps: {
                        style: { display: 'none' }
                      },
                      onOk() {
                        if (res.data.data.mfaMessageCode || res.data.data.mfaEmailCode) {
                          state.data.loginResult = res.data.data;
                          resolve(constant.MFA);
                        } else {
                          window.location.href = decodeURIComponent(`${res.data.data.data}`);
                        }
                      }
                    });
                  } else {
                    if (res.data.data.mfaMessageCode || res.data.data.mfaEmailCode) {
                      state.data.loginResult = res.data.data;
                      resolve(constant.MFA);
                    } else {
                      window.location.href = decodeURIComponent(`${res.data.data.data}`);
                    }
                  }
                }
              });
            } else {
              if (res.data.data.expires) {
                self.$confirm({
                  title: '提示',
                  content: `你的密码已经过期${res.data.data.expiresDays}天,请前往个人中心修改密码`,
                  okText: '知道了',
                  cancelButtonProps: {
                    style: { display: 'none' }
                  },
                  onOk() {
                    if (res.data.data.mfaMessageCode || res.data.data.mfaEmailCode) {
                      state.data.loginResult = res.data.data;
                      resolve(constant.MFA);
                    } else {
                      window.location.href = decodeURIComponent(`${res.data.data.data}`);
                    }
                  }
                });
              } else {
                if (res.data.data.mfaMessageCode || res.data.data.mfaEmailCode) {
                  state.data.loginResult = res.data.data;
                  resolve(constant.MFA);
                } else {
                  window.location.href = decodeURIComponent(`${res.data.data.data}`);
                }
              }
            }
          }
        }).catch((e) => {
          console.error(e);
          state.data.error = false;
          state.data.errorMsg = '登录失败';
          state.data.loading = false;
        });
      });
    },

    loginByTicket({ state }, item) {
      state.data.loading = true;
      const url = $url.getBackendApiUrl('authencation/oauth2/ssologin/verify/ticket');
      const data = { appKey: state.userLogin.appKey, ticket: item.ticket };
      http.post(url, data, url.default_headers).then((res) => {
        state.data.loading = false;
        if (res.status !== 200) {
          state.data.error = false;
          state.data.errorMsg = '登录失败';
          return;
        }

        if (!res.data.success) {
          state.data.error = false;
          state.data.errorMsg = res.data.errMessage;
          return;
        }

        if (res.data.success) {
          window.location.href = decodeURIComponent(`${res.data.data.data}`);
        }
      }).catch((e) => {
        console.error(e);
        state.data.loading = false;
        state.data.error = false;
        state.data.errorMsg = '登录失败';
      });
    },

    triggerCaptcha({ state }) {
      return new Promise((resolve) => {
        state.data.loading = true;
        const url = $url.getBackendApiUrl(`captcha/trigger?appKey=${state.userLogin.appKey}`);
        http.get(url).then((res) => {
          if (res.data.success && res.data.data) {
            state.data.isCaptcha = true;
            resolve(true);
          }

          if (res.data.success && res.data.data === false) {
            state.data.isCaptcha = false;
          }

          if (res.data.success === false) {
            state.data.isCaptcha = false;
            state.data.error = false;
            state.data.errorMsg = res.data.errMessage;
          }
          state.data.loading = false;
        }).catch((e) => {
          console.error(e);
          state.data.loading = false;
          state.data.error = false;
          state.data.errorMsg = '系统内部服务错误';
        });
      });
    },

    getCaptcha({ state }) {
      state.data.loading = true;
      const url = $url.getBackendApiUrl(`captcha/get?appKey=${state.userLogin.appKey}`);
      http.get(url, { responseType: 'arraybuffer' }).then((res) => {
        state.data.loading = false;
        if (res.data instanceof ArrayBuffer) {
          state.data.captchaUrl = 'data:image/jpg;base64,' + btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        }
      }).catch((e) => {
        console.error(e);
        state.data.loading = false;
        state.data.error = false;
        state.data.errorMsg = '获取验证码失败';
      });
    },

    changeLoginMode({ state }) {
      state.data.isFast = false;
      state.data.error = false;
      state.data.errorMsg = '';
    },

    loadCssCode({ state }) {
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.setAttribute('type', 'text/css');
      style.appendChild(document.createTextNode(state.data.appInfo.applyStyle.customCss));
      head.appendChild(style);
    },

  }
};

export default router;
