<template>
  <div class="main"
       v-bind:style="{ 'background-image': 'url(' + applyStyle.backgroundImageUrl + ')', 'background-color': applyStyle.backgroundColor, 'background-repeat': applyStyle.backgroundRepeat, 'background-size': applyStyle.backgroundSize, 'background-position': applyStyle.backgroundPosition }">
    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCustom: false
    };
  },

  computed: {
    applyStyle() {
      return this.$store.state.style.config;
    }
  }
};
</script>

<style lang="less" scoped>
@import '../assets/less/default';
@import '../assets/less/header';
@import '../assets/less/footer';

.main {
  height: @height;
  width: @width;
  background-color: #e3e3e3;
  overflow: hidden;
}

.main-content {
  width: @width;
  height: @height;
  overflow: hidden;
}
</style>
