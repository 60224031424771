<template>
  <div id="app">
    <home></home>
  </div>
</template>

<script>
import Home from './views/Home.vue';

export default {
  name: 'app',
  components: {
    Home,
  },
  created() {
    console.log('%c凯泰铭-中台-DRY※※※※※', 'color:red;');
    console.log('%c★★★★★★★★★★★★★★★★★', 'color:red;');
  },
};
</script>

<style lang="less" scoped>
@import 'assets/less/default.less';

#app {
  width: @width;
  height: @height;
  background-color: #e3e3e3;
}
</style>
