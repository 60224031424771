import session from './session';

const ticket = {};

function allTickets() {
  const tickets = [];
  if (localStorage) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.indexOf('tickets#_#') >= 0) {
        const keys = key.split('#_#');
        if (keys.length === 3) {
          const ticketObj = { session_id: keys[1], app_key: keys[2], key: key };
          tickets.push(ticketObj);
        }
      }
    }
  }
  return tickets;
}

ticket.setTicket = (data) => {
  // 删除与当前会话不一致的TICKET
  const tickets = allTickets();
  if (tickets && tickets.length > 0) {
    const sessionId = session.getSessionId();
    tickets.forEach((item) => {
      if (item.session_id !== sessionId) {
        localStorage.removeItem(item.key);
      }
    });
  }

  if (data && data.appKey && data.ticket) {
    if (tickets && tickets.length > 0) {
      tickets.forEach(item => {
        if (item.app_key === data.appKey) {
          localStorage.removeItem(item);
        }
      });
    }
    localStorage.setItem('tickets#_#' + session.getSessionId() + '#_#' + data.appKey, data.ticket);
  }
};

ticket.getTickets = () => {
  const tickets = allTickets();
  const results = [];
  if (tickets && tickets.length > 0) {
    tickets.forEach(item => {
      const value = localStorage.getItem(item.key);
      const result = { sessionId: item.session_id, appKey: item.app_key, ticket: value };
      results.push(result);
    });
  }

  return results;
};

export default ticket;
