export function getBackendApiUrl(url) {
  return `/sso-platform/${url}`;
}

export function addQueryString(url, param, val) {
  let result = url;
  if (url.indexOf('?') > 0) {
    result += `&${param}=${val}`;
  } else {
    result += `?${param}=${val}`;
  }
  return result;
}

export function getQueryString(url) {
  const paramMap = {};
  if (url && url.indexOf('?') >= 0) {
    url = url.substring(url.indexOf('?') + 1);
    const paramGroup = url.split('&');
    if (paramGroup) {
      paramGroup.map((item) => {
        const param = item.split('=');
        if (param && param.length > 1) {
          paramMap[param[0]] = param[1];
        }
        return item;
      });
    }
  }
  return paramMap;
}

// eslint-disable-next-line camelcase
export const default_headers = { headers: { 'Content-type': 'application/json;charset=UTF-8' } };
