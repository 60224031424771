/**
 * 记住我功能的js.
 * 同时将记住我的数据写入到localStorage和cookie中，双重保证.
 * @author pengyancheng
 */
import Cookies from 'js-cookie';

const rememberMe = {};

rememberMe.setRemember = (appKey, data) => {
  localStorage.setItem('is_remember', data);
  Cookies.set('is_remember', data, { expires: 30 });
  if (data === false) {
    localStorage.removeItem(`${appKey}_rememberme_user`);
    localStorage.removeItem(`${appKey}_rememberme_phone`);
    Cookies.remove(`${appKey}_rememberme_user`);
    Cookies.remove(`${appKey}_rememberme_phone`);
  }
};

rememberMe.isRemember = () => localStorage.getItem('is_remember') === 'true' || Cookies.get('is_remember') === 'true';

rememberMe.setUser = (appKey, data) => {
  if (rememberMe.isRemember()) {
    const result = {
      tenant: data.tenant,
      userName: data.userName,
      password: data.password
    };
    localStorage.setItem(`${appKey}_rememberme_user`, JSON.stringify(result));
    Cookies.set(`${appKey}_rememberme_user`, JSON.stringify(result), { expires: 30 });
  }
};

rememberMe.getUser = (appKey) => {
  if (rememberMe.isRemember()) {
    const result = localStorage.getItem(`${appKey}_rememberme_user`);
    if (result) {
      return JSON.parse(result);
    }

    const data = Cookies.get(`${appKey}_rememberme_user`);
    if (data) {
      return JSON.parse(data);
    }
  }
  return undefined;
};

rememberMe.setPhone = (appKey, data) => {
  if (rememberMe.isRemember()) {
    const result = {
      tenant: data.tenant,
      phone: data.phone
    };
    localStorage.setItem(`${appKey}_rememberme_phone`, JSON.stringify(result));
    Cookies.set(`${appKey}_rememberme_phone`, JSON.stringify(result), { expires: 30 });
  }
};

rememberMe.getPhone = (appKey) => {
  if (rememberMe.isRemember()) {
    const result = localStorage.getItem(`${appKey}_rememberme_phone`);
    if (result) {
      return JSON.parse(result);
    }

    const data = Cookies.get(`${appKey}_rememberme_phone`);
    if (data) {
      return JSON.parse(data);
    }
  }
  return undefined;
};

export default rememberMe;
