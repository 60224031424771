import Vue from 'vue';
import Vuex from 'vuex';
import router from './modules/router';
import style from './modules/style';
import login from './modules/login';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    router,
    style,
    login
  },
});
