const router = {
  state: {
    routerList: [],
    currentRouter: {}
  },
  mutations: {
    changeRouterList(state, payload) {
      state.routerList = payload;
    },

    changeCurrentRouter(state, payload) {
      state.currentRouter = payload;
    }
  }
};

export default router;
